<style>
.scrolly {
	height: 70vh;
	overflow-y: scroll;
}

.movable {
	cursor: move;
}

.ghost {
	background-color: grey;
}

.draggable {
	margin-left: 10px;
}

.empty:after {
	padding: 10px;
	content: 'drop here';
	/* background-color: rgba(249, 249, 249, 1); */
	/* border: 1px dashed rgb(206, 206, 206); */
	-webkit-border-radius: 5px;
	border-radius: 5px;
}
</style>

<template>
	<errorContainer :error="erreur">
		<div>
			<div class="row">
				<div class="col-6 scrolly" v-if="departmentsAndFunctions">
					<b-card no-body v-for="department in departmentsAndFunctions" :key="department.message">
						<div slot="header">
							{{ department.message }}
							<div class="card-header-actions">
								<b-link class="card-header-action btn-minimize" v-b-toggle="department.id">
									<i class="icon-arrow-down"></i>
								</b-link>
							</div>
						</div>
						<b-collapse :id="department.id">
							<b-card-body>
								<div v-for="(fonction, index) in department.functions" :key="fonction.message">
									<p>{{ fonction.message }}</p>
									<draggable
										class="list-group draggable"
										:list="fonction.users"
										group="people"
										@change="log"
										ghost-class="ghost"
										v-bind:class="{ empty: fonction.users.length == 0 }"
									>
										<div class="list-group-item" v-for="user in fonction.users" :key="user.name">
											<userDisplay :user="user"></userDisplay>
										</div>
									</draggable>
									<br v-if="index != department.functions.length - 1" />
								</div>
								<br />
							</b-card-body>
						</b-collapse>
					</b-card>
				</div>
				<div class="col-6 scrolly" v-if="usersWithoutFunction">
					<b-card header="Users">
						<draggable
							class="list-group"
							:list="usersWithoutFunction"
							group="people"
							@change="log"
							ghost-class="ghost"
							v-bind:class="{ empty: usersWithoutFunction.length == 0 }"
						>
							<div class="list-group-item" v-for="user in usersWithoutFunction" :key="user.name">
								<userDisplay :user="user"></userDisplay>
							</div>
						</draggable>
					</b-card>
				</div>
			</div>
			<!-- <div class="row">
    <div class="col-12">
      {{ departmentsAndFunctions }}
    </div>
  </div> -->
		</div>
	</errorContainer>
</template>

<script>
import draggable from 'vuedraggable';
import gql from 'graphql-tag';
import { getDepartmentsAndFunctions } from '@/cruds/department.crud.js';
import { getUsersWithoutFunction, updateUserFunction } from '@/cruds/users.crud.js';

const userDisplay = {
	props: {
		user: Object
	},
	methods: {
		userTitle: function (user) {
			if (user.name.length > 0 || user.firstName.length > 0) {
				return user.name + ' ' + user.firstName;
			} else {
				return user.email;
			}
		}
	},
	template:
		'<div><span class="movable">{{ userTitle(user) }} </span> <a :href="\'/#/users/\' + user.id " target="_blank"><i class="icon-info"></i></a>   </div>'
};

const query_users = gql`
	query {
		GetUsersWithoutFunction {
			id
			name
			firstName
			email
			userProjectID
		}
	}
`;

const mutation_department = gql`
	mutation ($UserProjectID: ID!, $DepartmentValue: Int!, $FunctionValue: Int!) {
		UpdateUserFunction(UserProjectID: $UserProjectID, DepartmentValue: $DepartmentValue, FunctionValue: $FunctionValue)
	}
`;

export default {
	name: 'two-lists',
	display: 'Two Lists',
	order: 1,
	components: {
		draggable,
		userDisplay
	},
	mounted: async function () {
		this.fetchDepartmentsAndFunction();
		this.fetchUserWithoutFunctions();
	},
	data() {
		return {
			departmentsAndFunctions: null,
			usersWithoutFunction: null,
			erreur: {}
		};
	},

	methods: {
		async fetchDepartmentsAndFunction() {
			const data_ = await getDepartmentsAndFunctions();
			this.departmentsAndFunctions = data_.GetDepartmentsAndFunctions;
			console.log(this.departmentsAndFunctions);
		},
		async fetchUserWithoutFunctions() {
			this.usersWithoutFunction = await getUsersWithoutFunction();
			console.log(this.usersWithoutFunction);
		},
		updateDepartment: function (UserProjectID, DepartmentValue, FunctionValue) {
			updateUserFunction(UserProjectID, DepartmentValue, FunctionValue).then((result)=>{
				console.log('saved:', result);
			}).catch((error)=>{
				this.erreur = error;
			})

		},
		findDepartmentAndFunction: function (UserProjectID) {
			for (var i = 0; i < this.departmentsAndFunctions.length; i++) {
				for (var j = 0; j < this.departmentsAndFunctions[i].functions.length; j++) {
					for (var k = 0; k < this.departmentsAndFunctions[i].functions[j].users.length; k++) {
						if (this.departmentsAndFunctions[i].functions[j].users[k].userProjectID == UserProjectID) {
							var department = this.departmentsAndFunctions[i].value;
							var func = this.departmentsAndFunctions[i].functions[j].value;
							return [department, func];
						}
					}
				}
			}
			// Dans aucun départment => a été remis à droite
			return [0, 0];
		},
		log: function (evt) {
			window.console.log(evt);

			if (evt.added) {
				window.console.log('hooora');
				window.console.log(evt.added.element.firstName);
				var values = this.findDepartmentAndFunction(evt.added.element.userProjectID);
				this.updateDepartment(evt.added.element.userProjectID, values[0], values[1]);
			}
		}
	}
};
</script>
